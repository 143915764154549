import * as Yup from 'yup';
import { array, boolean, mixed, number, object, ref, string } from 'yup';

// Regex pattern for phone number validation (you can adjust this as needed)
const phoneNumberRegex =
  /^(?:\+?\d{1,4}|\d{1,4})[\s\-]?(?=\D*\d)(\d[\s\-]?){7,14}\d$/;

export const LoginSchema = object().shape({
  email: string()
    .email('This field must be an email')
    .required('Email is required'),
  password: string().required('Password is required'),
});

export const ForgotPasswordSchema = object().shape({
  email: string()
    .email('This field must be an email')
    .required('Email is required'),
});

export const ProfileDetailsUpdateSchema = object().shape({
  firstName: string().required('First Name is required'),
  lastName: string().required('Last Name is required'),
  email: string()
    .email('This field must be an email')
    .required('Email is required'),
  phoneNumber: string().matches(phoneNumberRegex, 'Phone number is not valid'),
});

export const ProfilePasswordUpdateSchema = object().shape({
  currentpassword: string().required('Current password is required'),
  newpassword: string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain an uppercase letter')
    .matches(/[\w]/, 'Password must contain a special character')
    .required('Password is required'),
  confirmPassword: string()
    .oneOf([ref('newpassword')], 'Passwords must match')
    .required('Password confirmation is required'),
});

export const ProfileStripeIntegrationCheckerScema = object().shape({
  currentpassword: string().required('Current password is required'),
});

export const PaymentIntegrationScema = object().shape({
  api_key: string().required('API Key is required'),
  secret_key: string().required('Secret Key is required'),
});

export const AccountingIntegrationSchema = (xeroConnected: boolean) =>
  object().shape({
    xero_client_id: xeroConnected
      ? string().nullable()
      : string().required('Xero Client Id is required'),
    xero_client_secret: xeroConnected
      ? string().nullable()
      : string().required('Xero Client Secret is required'),
    xero_webhook_key: xeroConnected
      ? string().nullable()
      : string().required('Xero Webhook Key is required'),
  });

export const ResetPasswordSchema = object().shape({
  password: string().required('Password is required'),
  password_confirmation: string().required('Password confirmation is required'),
});

export const RegisterSchema = object().shape({
  firstName: string().required('First Name is required'),
  lastName: string().required('Last Name is required'),
  email: string()
    .email('This field must be an email')
    .required('Email is required'),
  phoneNumber: string().matches(phoneNumberRegex, 'Phone number is not valid'),
  password: string().required('Password is required'),
});

export const CompanyDetailsSchema = object().shape({
  name: string().required('Please Enter the name of your business'),
  email: string()
    .email('Enter a Valid Email Address')
    .required('Email is required'),
  phone: string().matches(phoneNumberRegex, 'Phone number is not valid'),
  website_url: string().url('Invalid URL').nullable(),
  // country: string().required('Please Select an Address'),
  address: object().shape({
    street_number: string().nullable(),
    street_name: string().required('Please Enter Street Name'),
    city: string().required('Please Enter City'),
    state: string().nullable(),
    postal_code: string().required('Please Enter Postal Code'),
  }),
  timezone: string().required('Please Select a Timezone'),
  first_day_of_week: number().required('Please Select a First Day of the Week'),
  date_format: string().required('Please Select a Date format'),
  time_format: string().required('Please Select a Time format'),
});

export const TaxRatesSchema = object().shape({
  taxName: string().required('Name is required'),
  taxRate: string().required('Rate is required'),
  description: string().required('Description is required'),
});

export const CompanyTaxDetailsSchema = object().shape({
  taxIdNumber: string().required('Tax ID Number is required'),
});
export const SocialLinkUpdateSchema = object().shape({
  linkedin: string(),
  facebook: string(),
  x: string(),
  instagram: string(),
  google: string(),
});

export const CustomersSchema = object().shape({
  title: mixed()
    .test(
      'valid-title',
      'Title is required',
      (value) => value && value !== '1', // Ensure value is defined and not '1' (No Title)
    )
    .required('Title is required'),
  name: string().required('Name is required'),
  company_name: string().optional().nullable(),
  phone: string().matches(phoneNumberRegex, 'Phone number is not valid'),
  email: string().email('Invalid email address').optional().nullable(),
  referred_by: string().optional().nullable(),
  sites: array().of(
    object().shape({
      name: string().optional().nullable(),
      details: string().optional().nullable(),
      address: object().shape({
        street_number: string().optional().nullable(),
        street_name: string().optional().nullable(),
        suburb: string().optional().nullable(),
        city: string().optional().nullable(),
        // region: string().optional().nullable(),
        country: string().optional().nullable(),
        postal_code: string().optional().nullable(),
        search_keyword: string().nullable().optional(),
      }),
    }),
  ),
});

export const CustomersEditSchema = object().shape({
  title: mixed()
    .test(
      'valid-title',
      'Title is required',
      (value) => value !== '1', // Assuming "No Title" has id = '1'
    )
    .required('Title is required'),
  name: string().required('Name is required'),
  company_name: string().optional().nullable(),
  phone: string().matches(phoneNumberRegex, 'Phone number is not valid'),
  email: string().email('Invalid email address').optional().nullable(),
  referred_by: string().optional().nullable(),
});

export const TeamMemberSchema = object().shape({
  first_name: string().required('First name is required'),
  last_name: string().optional().nullable(),
  phone: Yup.string()
    .optional()
    .test('is-valid-phone', 'Phone number is not valid', function (value) {
      // Check if phone is not empty and validate it with the regex
      if (value && value.trim() !== '') {
        return phoneNumberRegex.test(value); // Validate with the provided phone number regex
      }
      return true; // If phone is not provided, it's valid (optional field)
    })
    .nullable(),
  email: string().required('Email is required').email('Invalid email address'),
  trade_certifying_registration_number: string().optional().nullable(),
  vehicle_number: string().optional().nullable(),
  gps_device_imei_number: Yup.string()
    .optional()
    .test(
      'length',
      'The gps device imei number field must be 15 characters.',
      function (value) {
        if (value && value.trim() !== '') {
          return value.length === 15;
        }
        return true;
      },
    )
    .nullable(),
  // labor_cost: string().optional().nullable(),
  position: string().required('Position is required'),
  address: object()
    .shape({
      street_number: string().optional().nullable(),
      street_name: string().optional().nullable(),
      suburb: string().optional().nullable(),
      city: string().optional().nullable(),
      // region: string().optional().nullable(),
      postal_code: string().optional().nullable(),
      country: string().optional().nullable(),
      search_keyword: string().optional().nullable(),
    })
    .nullable(),
  image_url: string().optional().nullable(),
});

export const ProductSchema = object().shape({
  item_type: string().required('Item type is required'),
  name: string()
    .required('Name is required')
    .max(255, 'The name cannot exceed 255 characters in length'),
  cost: string().required('Cost is required'),
  unit_price: string().required('Unit price is required'),
  is_tax_exempted: string().optional().nullable(),
});

export const ProductFormSchema = object().shape({
  item_type: string().required('Item type is required'),
  name: string()
    .required('Name is required')
    .max(255, 'The name cannot exceed 255 characters in length'),
  item_code: string().optional().nullable(),
  description: string().optional().nullable(),
  cost: string().required('Cost is required'),
  markup: string(),
  unit_price: string().required('Unit price is required'),
  image_url: string().optional().nullable(),
  is_tax_exempted: number().optional().nullable(),
});

export const ProductImportSchema = object().shape({
  attachment: string().required('Product CSV or Excel file is required'),
});

export const CustomersSiteSchema = object().shape({
  name: string().required(),
  details: string().optional().nullable(),
  address: object().shape({
    street_number: string().optional().nullable(),
    street_name: string().optional().nullable(),
    suburb: string().optional().nullable(),
    city: string().optional().nullable(),
    // region: string().optional().nullable(),
    country: string().optional().nullable(),
    postal_code: string().optional().nullable(),
    search_keyword: string().optional().nullable(),
  }),
});

export const CustomersNoteSchema = object().shape({
  note: string().required('Note is required field'),
  attachment: object().shape({
    link: string().optional().nullable(),
    size: string().optional().nullable(),
    name: string().optional().nullable(),
  }),
});

export const ExpenseSchema = object().shape({
  name: string().required('Name is required'),
  date: string().required('Date is required'),
  job: object().shape({
    id: string().optional().nullable(),
    name: string().optional().nullable(),
  }),
  details: string().optional().nullable(),
  amount: string().required('Amount is required'),
  tax_rate_id: string().nullable(),
  attachment_url: object().shape({
    link: string().optional().nullable(),
    size: string().optional().nullable(),
    name: string().optional().nullable(),
  }),
});

export const EnquirySchema = (
  anyTimeForAvailability: boolean,
  onSiteAssessmentIsRequired: boolean,
  anyTimeForAssessment: boolean,
) =>
  object().shape({
    // customer_id: string().optional().nullable(),
    customer_id: string().required('Customer is required'),
    customer_site_id: string().optional().nullable(),
    // customer_site_id: string().required('Customer site is required'),
    title: string().required('Title is required'),
    requested_on: string().required('Request date is required'),
    // customer_id: string(),
    // customer_site_id: string(),
    // title: string(),
    // requested_on: string(),

    details: string(),
    expected_date: string().required('Expected date is required'),
    expected_time: !anyTimeForAvailability
      ? string().required('Expected time is required')
      : string().nullable(),
    // expected_date: string(),
    // expected_time: string().nullable(),

    onsite_assessment_required: boolean(),
    assessment: object().shape({
      details: string().nullable().optional(),
      start_date: onSiteAssessmentIsRequired
        ? string().required('Assessment start date is required')
        : string().nullable(),
      end_date: onSiteAssessmentIsRequired
        ? string().required('Assessment end date is required')
        : string().nullable(),
      // start_date: string().nullable(),
      // end_date: string().nullable(),

      start_time:
        onSiteAssessmentIsRequired && !anyTimeForAssessment
          ? string().required('Assessment start time is required')
          : string().nullable(),
      end_time:
        onSiteAssessmentIsRequired && !anyTimeForAssessment
          ? string().required('Assessment start time is required')
          : string().nullable(),
      // start_time: string().nullable(),
      // end_time: string().nullable(),

      // assessmentMembers: array().of(string()).optional().notRequired(),
      assessmentMembers: Yup.array()
        .of(Yup.string()) // Array of strings
        .transform((originalValue) => {
          // If it's not already an array, convert it to an array
          if (!Array.isArray(originalValue)) {
            return [originalValue]; // Wrap string in an array
          }
          return originalValue; // If it's already an array, return it as is
        })
        .optional() // Optional field
        .notRequired(), // Not required field
      send_notification: boolean(),
    }),
    note_text: string().optional().nullable(),
    note_attachment: string().optional().nullable(),
    // notes: array().of(object().shape({ note: string(), attachment: string() })),
  });

export const EnquiryNoteSchema = object().shape({
  uuid: string().optional().nullable(),
  note: string(),
  attachment: string().optional().nullable(),
});

export const TimeSheetSchema = object().shape({
  category: string().required('Job is required'),
  start: string().required('Start is required'),
  end: string().required('End is required'),
  notes: string().optional().nullable(),
  baseDate: string().required('Date is required'),
  team_member_id: string().required('Member is required'),
});

export const TimeSheetFromJobsSchema = object().shape({
  start: string().required('Start is required'),
  end: string().required('End is required'),
  notes: string().optional().nullable(),
});

export const AddNoteSchema = object().shape({
  note: string().required('Note is required'),
  file: string().nullable(),
});
export const CreateQuoteSchema = object().shape({
  quote_number: string().required('Quote number is required'),
  rating: number().required('Quote rating is required'),
  title: string().required('Quote title is required'),
  // client_message: string().required('Client message is required'),
  // disclaimer: string().required('Disclaimer is required'),
});

export const CreateJobSchema = (isJobTypeRecurring: boolean) =>
  object().shape({
    customer_id: string().required('Customer is required'),
    customer_site_id: string(),
    job_number: string().optional().nullable(),
    title: string().required('Job title is required'),
    details: string().optional().nullable(),
    type: string().required('Type is required'),
    recurring_type: isJobTypeRecurring
      ? string().required('Repeat duration is required')
      : string().nullable().optional(),
    start_date: !isJobTypeRecurring
      ? string().required('Start date is required')
      : string().nullable().optional(),
    end_date: !isJobTypeRecurring ? string() : string().nullable().optional(),
    start_time: !isJobTypeRecurring ? string() : string().nullable().optional(),
    end_time: !isJobTypeRecurring ? string() : string().nullable().optional(),
    recurring_data: isJobTypeRecurring
      ? object()
          .shape({
            start_date: string().required('Start date is required'),
            end_date: string().required('End date is required'),
          })
          .optional()
          .nullable()
      : object()
          .shape({
            start_date: string().nullable(),
            end_date: string().nullable(),
          })
          .optional()
          .nullable(),
    // team_members: array()
    //   .of(
    //     object().shape({
    //       uuid: string().required('Team member UUID is required'),
    //       full_name: string().required('Team member name is required'),
    //     }),
    //   )
    //   .min(1, 'At least one team member is required') // Ensure at least one team member is selected
    //   .required('Team members are required'),
    // job_products: array().optional(),
    notes: array().optional().nullable(),
  });

export const InvoiceSchema = object().shape({
  job_id: string().optional().nullable(),
  customer_id: string().optional().nullable(),
  invoice_number: string().required('Invoice number is required'),
  title: string().required('Subject is required'),
  issued_date: string().required('Issued date is required'),
  payment_due: string().required('Payment date is required'),
  discount: number().optional().nullable(),
  tax_rate: number().nullable(),
  tax_rate_id: string().nullable(),
  customer_message: string().optional().nullable(),
  notes: array().optional().nullable(),
});

export const InvoiceCollectPaymentSchema = object().shape({
  method: number().required('Payment Method is required'),
  amount: string().required('Amount is required'),
  date: string().required('Date is required'),
  reference: string().required('Reference number is required'),
  details: string().optional(),
});

export const InvoiceNoteSchema = object().shape({
  note: string().required('Text value is required'),
  attachment: string().optional().nullable(),
});

export const UpdateJobSchema = () =>
  object().shape({
    customer_id: string().required('Customer is required'),
    customer_site_id: string(),
    job_number: string().optional().nullable(),
    title: string().required('Job title is required'),
    details: string().optional().nullable(),
    type: string().optional().nullable(),
    recurring_type: string().nullable().optional(),
    start_date: string().nullable().optional(),
    end_date: string().nullable().optional(),
    start_time: string().nullable().optional(),
    end_time: string().nullable().optional(),
    recurring_data: object()
      .shape({
        start_date: string().nullable().optional(),
        end_date: string().nullable().optional(),
      })
      .optional()
      .nullable(),
    // team_members: array()
    //   .of(
    //     object().shape({
    //       uuid: string().required('Team member UUID is required'),
    //       full_name: string().required('Team member name is required'),
    //     }),
    //   )
    //   .min(1, 'At least one team member is required') // Ensure at least one team member is selected
    //   .required('Team members are required'),
    // job_products: array().optional(),
    notes: array().optional().nullable(),
  });

export const UploadPhotos = object().shape({
  file: string(),
});
export const SubscriptionSchema = object().shape({
  users: number().required('Number of users is required'),
  duration: number().required('Number of months is required'),
});

export const SupplierSchema = object().shape({
  name: string().required('Name is required'),
  contact_name: string().optional().nullable(),
  phone: string().optional().nullable(),
  email: string().optional().nullable(),
  // type: number(),
  address: object().shape({
    street_number: string().optional().nullable(),
    street_name: string().required('Street name is required'),
    suburb: string().optional().nullable(),
    city: string().required('City is required'),
    region: string().optional().nullable(),
    postal_code: string().required('Postal code is required'),
    country: string().required('Country is required'),
    latitude: string(),
    longitude: string(),
    search_keyword: string(),
  }),
  notes: array().optional().nullable(),
});

export const SupplierNoteSchema = object().shape({
  note: string().required('Text value is required'),
  attachment: string().optional().nullable(),
});

export const SupplierImportSchema = object().shape({
  attachment: string().required('Supplier CSV or Excel file is required'),
});

export const EmailTemplateSchema = object().shape({
  body: string().required('Body is required'),
});

export const ReferFriendSchema = object().shape({
  name: string().required('Name is required'),
  email: string()
    .email('This field must be an email')
    .required('Email is required'),
  company_name: string().nullable(),
  phone: string()
    .optional()
    .test('is-valid-phone', 'Phone number is not valid', function (value) {
      if (value && value.trim() !== '') {
        return phoneNumberRegex.test(value); // Validate with the provided phone number regex
      }
      return true; // If phone is not provided, it's valid (optional field)
    })
    .nullable(),
});

export const ExpectedLabourSchema = object().shape({
  member: string().nullable(),
  date: string().required('Date is required'),
  duration: string().required('Duration is required'),
  cost: string().required('Cost is required'),
  note: string().nullable(),
});

export const ExpectedExpenseSchema = object().shape({
  item: string().required('Item is required'),
  amount: string().required('Amount is required'),
});

export const InventoryTransferSchema = object().shape({
  from: string().required('From field is required'),
  to: string().required('To field is required'),
  item: string().required('Item is required'),
});
