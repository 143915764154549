'use client';

import { LoginSchema } from '@/helpers/schemas';
import { LoginFormType } from '@/helpers/types';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { useRouter, useSearchParams } from 'next/navigation';
import { useCallback, useState } from 'react';
import TextInput from '../input/TextInput';
import BaseButton from '../button/BaseButton';
import NextLink from 'next/link';
import { toast } from 'react-toastify';
import { AppDispatch } from '@/redux/store';
import { fetchLogin } from '@/redux/thunk/authThunk';
import logo from '../../../public/assets/images/logo.svg';
import Image from 'next/image';

type LoginArgsType = {
  email: string;
  password: string;
};

export const Login = () => {
  const router = useRouter();
  const dispatch = useDispatch<AppDispatch>();

  const searchParams = useSearchParams();
  const r_id = searchParams.get('r_id');

  const [isLoading, setIsLoading] = useState(false);
  const initialValues: LoginFormType = { email: '', password: '' };

  const handleLogin = useCallback(
    async (
      values: LoginArgsType,
      { setFieldError, resetForm }: FormikHelpers<LoginArgsType>,
    ) => {
      setIsLoading(true);

      try {
        // Use `unwrap()` to throw errors from the thunk
        await dispatch(fetchLogin(values)).unwrap();

        resetForm();
      } catch (error: any) {
        // Handle rejected values (serialized errors)
        if (error.messages) {
          Object.keys(error.messages).forEach((field) => {
            setFieldError(field, error.messages[field][0]);
          });
        } else {
          toast.error(error.message || 'An unexpected error occurred.');
        }
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, router],
  );

  return (
    <div className="flex flex-col justify-start items-start w-full h-full">
      <Image
        src={logo}
        alt="logo"
        width={250}
        priority
        className="cursor-pointer pt-8 md:pt-0"
      />

      <h6 className="pt-10">Sign In</h6>
      <h4 className="text-body-md pt-7">
        Welcome back! Please enter your credentials below to access your ZYNOFF
        account.
      </h4>

      <Formik
        initialValues={initialValues}
        validationSchema={LoginSchema}
        onSubmit={handleLogin}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <div className="w-full">
            <div className="mb-4 w-full pt-10">
              <TextInput
                variant="bordered"
                labelPlacement="outside"
                label="Email"
                type="email"
                name={'email'}
                placeholder="john@zynoff.co.nz"
                value={values.email}
                size="md"
                isInvalid={!!errors.email && !!touched.email}
                errorMessage={errors.email}
                onChange={handleChange}
                isDisabled={isLoading}
                className="font-bold"
              />
            </div>

            <div className="w-full pt-1.5 mb-4">
              <TextInput
                variant="bordered"
                labelPlacement="outside"
                label="Password"
                name="password"
                type="password"
                placeholder="************"
                value={values.password}
                size="md"
                isInvalid={!!errors.password && !!touched.password}
                errorMessage={errors.password}
                onChange={handleChange}
                isDisabled={isLoading}
                className="font-bold"
                endContent={
                  <div className="text-end">
                    <NextLink
                      style={{ cursor: 'pointer' }}
                      className="text-light-linkColor-primary font-normal"
                      href={
                        r_id
                          ? `/forgot-password?r_id=${r_id}`
                          : '/forgot-password'
                      }
                    >
                      Forgot&nbsp;Password?
                    </NextLink>
                  </div>
                }
              />
            </div>

            <div className="flex justify-between items-center w-full pt-8">
              <BaseButton
                isDisabled={isLoading}
                color="primary"
                width="100%"
                size="md"
                radius="sm"
                onClick={() => handleSubmit()}
                isLoading={isLoading}
              >
                Sign in
              </BaseButton>
            </div>

            <div className="w-full pt-8 text-center text-body-md">
              <span style={{ cursor: 'pointer' }}>
                Don&lsquo;t you have an account yet?
              </span>
              <NextLink
                style={{ cursor: 'pointer' }}
                className="text-light-linkColor-primary pl-1"
                href={r_id ? `/register?r_id=${r_id}` : '/register'}
              >
                Sign up
              </NextLink>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};
